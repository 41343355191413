import { VIRTUAL_PROPS_ACCESSOR } from '@core/shared/virtual-properties';
import { MembershipRegistrationFormInsuranceSimpleComponent } from './registration-form.component';
import { InsuranceSimpleMembershipPlugin } from '@pflegetheke/membership-plugins-insurance/shared';
import { MembershipRegistrationPluginSummaryComponent } from '@common/membership/frontend-shared';
import { RouterLinkResolverService } from '@core/shared/feature';
export class InsuranceSimpleFrontendMembershipPlugin extends InsuranceSimpleMembershipPlugin {
  getRegistrationComponent() {
    return MembershipRegistrationFormInsuranceSimpleComponent;
  }
  getSummaryComponent() {
    return MembershipRegistrationPluginSummaryComponent;
  }
  getAccountData(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      username: data.email,
      // password will be generated on server side
      [VIRTUAL_PROPS_ACCESSOR]: {
        phone: data.phone,
        allow3rdPartyContact: data.allow3rdPartyContact
      }
    };
  }
  getDataToPersist(data) {
    return {
      insuranceId: data.insuranceId,
      insuranceMembershipId: data.insuranceMembershipId
    };
  }
  getCategorization(data) {
    return data.insuranceId;
  }
  getRegistrationCompletedButton() {
    const resolver = this.injector.get(RouterLinkResolverService);
    const link = resolver.resolveRouterLink('frontend', 'questionCategories');
    return {
      label: "Weiter zu den Videos",
      link
    };
  }
}
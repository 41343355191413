
<ng-template [ngLet]="isAdmin$|async" let-isAdmin>
	<ng-template [ngIf]="accessIsReady$|async">
		<ng-template [ngIf]="isAdmin">
			<layout-contentbars-wrapper>
				<layout-contentbar-top>
					<a routerLink="" class="logo" >
						<ui-pflegetheke-logo [quad]="true" [background]="true" scaling="match-height" openConsole></ui-pflegetheke-logo>
					</a>
					<div class="left">
						<h2 class="page-title" [innerHTML]="pageTitle$|async"></h2>
					</div>
					<ul class="right topbar-menu fadeInDown" >
						<li *ngIf="loggedIn$|async" >
							<maintenance-info-icon></maintenance-info-icon>
						</li>
						<li *ngIf="loggedIn$|async" #profile >
							<ui-menu #menu [popup]="true" [model]="userTopMenu" appendTo="body"></ui-menu>
							<a (click)="menu.toggle($event)">
								<!-- <img src="assets/layout/images/avatar.png" alt="manhattan-layout" width="40" /> -->
								<span class="topbar-item-name profile-name" *ngIf="userData$|async; else loadingUsername; let userData">
									Hi {{userData?.firstName}}!
									<ui-icon icon="chevron-down"></ui-icon>
								</span>
								<ng-template #loadingUsername>
									<span class="topbar-item-name profile-name" >
										lade...
									</span>
								</ng-template>
							</a>
						</li>
					</ul>
				</layout-contentbar-top>

				<layout-sidebar-wrapper>
					<router-outlet-fullscreen></router-outlet-fullscreen>
					<layout-sidebar [menuModel]="menuModel" ></layout-sidebar>
				</layout-sidebar-wrapper>
				
			</layout-contentbars-wrapper>
		</ng-template>

		<ng-template [ngIf]="!(isAdmin$|async)">
			<div class="layout-content" >
				<user-login [resetPasswordEnabled]="false">
					<ui-pflegetheke-logo [scaling]="140" [background]="true" style="margin:0 auto" openConsole></ui-pflegetheke-logo>
				</user-login>
			</div>
		</ng-template>
	</ng-template>


<page-connection-error>
	<ui-pflegetheke-logo background="true" [scaling]="100" id="logo"></ui-pflegetheke-logo>
</page-connection-error>
<page-login-overlay></page-login-overlay>
<app-message-toast position="bottom-right"></app-message-toast>
<ui-confirmDialog header="Bestätigung erforderlich" icon="exclamation-triangle"></ui-confirmDialog>
<versioncheck></versioncheck>
<console-toggle></console-toggle>